.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.roboto-sans-serif {
  font-family: "Roboto", sans-serif;
}

.pp-neue-montreal {
  font-family: "PP Neue Montreal", sans-serif;
}

.neue-power-sans-serif {
  font-family: "Neue Power", sans-serif;
}

.neue-bold {
  font-family: "Neue-bold";
}

.poppins-sans-serif {
  font-family: "Poppins", sans-serif;
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #001316;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

.qr-code-container {
  background-color: #DBFE87;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 184px;
  padding: 10px;
  position: fixed;
  right: 1.313rem;
  top: 24rem;
  width: 111px;
  z-index: 10001;
}
.qr-code-container .close-btn {
  position: absolute;
  max-width: 20%;
  top: -5px;
  left: -10px;
}
.qr-code-container .qr-code-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qr-code-container .qr-code-text p {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 0.688rem;
  font-weight: 600;
  line-height: 21px;
}
.qr-code-container .qr-code-text p span {
  margin-left: 0.626rem;
}
.qr-code-container .qr-code-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.qr-code-container .qr-code-box img {
  max-width: 5rem;
  border-radius: 8px;
  margin: auto;
  margin-left: 4px;
}
.qr-code-container .qr-code-box .platform-icons {
  margin-top: 5px;
}
.qr-code-container .qr-code-box .platform-icons img {
  max-width: 20px;
}
.qr-code-container .down-arrow-icons {
  display: flex;
  gap: 0.1rem;
  justify-content: center;
  margin-top: 6px;
}
.qr-code-container .down-arrow-icons img {
  max-width: 1.625rem;
}

.custom-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
  z-index: 1000000;
}
.custom-overlay #login-overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: #001316;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 41.5rem;
  height: 33.5rem;
  background: #fffaf7;
  border-radius: 1.25rem;
  padding: 1.5rem 2.5rem 1.875rem;
  text-align: center;
}
.custom-overlay #login-overlay-content .close-btn {
  position: absolute;
  top: -17px;
  right: 24px;
}
.custom-overlay #login-overlay-content .close-btn img {
  width: 14px;
}
.custom-overlay #login-overlay-content h3 {
  font-family: "neueMontreal-Regular";
  font-size: 2rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
  margin-top: 2.938rem;
}
.custom-overlay #login-overlay-content p {
  font-family: "neueMontreal-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 1rem;
}
.custom-overlay #login-overlay-content img {
  margin-top: 2.125rem;
  width: 14.875rem;
}
.custom-overlay #login-overlay-content h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  color: #001316;
}
.custom-overlay #login-overlay-content .down-arrow-icons {
  gap: 5.125rem;
  display: flex;
  justify-content: center;
  margin-top: -22px;
}
.custom-overlay #login-overlay-content .down-arrow-icons img {
  width: 2.031rem;
}
.custom-overlay #login-overlay-content .app-stores {
  gap: 1rem;
  display: flex;
  justify-content: center;
  margin-top: -22px;
}
.custom-overlay #login-overlay-content .app-stores img {
  width: 8.125rem;
}

@media (max-width: 900px) {
  .qr-code-container {
    display: none;
  }
}/*# sourceMappingURL=scanner-modal.css.map */
